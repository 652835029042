import * as React from 'react';
import Seo from "../components/seo";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';
import { StaticImage } from "gatsby-plugin-image";

export class ProjectsPage extends React.Component {
  render() {
    return (
      <>
        <Navigation />
        <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
          <div className="max-w-7xl mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
              <main className="lg:col-span-8 lg:col-start-1">
                <h2 className="text-4xl text-gray-900 mb-12">Projects</h2>
                <div className="space-y-16">
                  {/* Cinetimes */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/cinetimes.jpg"
                          alt="Cinetimes Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://cinetimes.org" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">Cinetimes</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          Free streaming platform aggregating movies, cartoons, and documentaries from official sources. 
                          Features a Netflix-like interface with machine learning recommendations and sends a weekly newsletter 
                          to subscribers. Available in French, Spanish and English, monetized through advertisements.
                        </p>
                        <a 
                          href="https://cinetimes.org" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>

                  {/* Fotoia */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/fotoia.jpg"
                          alt="Fotoia Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://fotoia.es" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">Fotoia</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          AI photography platform that transforms selfies into professional photos and artistic portraits. 
                          Features include multiple style options, custom model training, and an AI studio for image generation. 
                          Offers tiered subscription plans with different levels of generation and training credits.
                        </p>
                        <a 
                          href="https://fotoia.es" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>

                  {/* WebsiteHunt */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/websitehunt.jpg"
                          alt="WebsiteHunt Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://www.websitehunt.co" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">WebsiteHunt</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          A platform showcasing little-known yet useful websites, complemented by a weekly newsletter featuring 
                          the most upvoted sites. With over 2,350 email subscribers, it serves as a discovery platform for 
                          interesting web projects.
                        </p>
                        <a 
                          href="https://www.websitehunt.co" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>

                  {/* Proximovie */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/proximovie.jpg"
                          alt="Proximovie Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://proximovie.com" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">Proximovie</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          Movie recommendations website leveraging user votes and AI technology. Features various similarity 
                          algorithms using GPT embeddings for plot analysis, visual embeddings for posters, and metadata 
                          comparison. Includes thousands of keyword pages where users can vote on movies.
                        </p>
                        <a 
                          href="https://proximovie.com" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>

                  {/* Alternativas */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/alternativas.jpg"
                          alt="Alternativas Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://alternativas.io" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">Alternativas</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          Spanish crowdsourced software alternatives website where users can login, add software, search 
                          alternatives, and participate in community discussions through comments and upvotes.
                        </p>
                        <a 
                          href="https://alternativas.io" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>

                  {/* Empleovino */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/empleovino.jpg"
                          alt="Empleovino Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://empleovino.es" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">Empleovino</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          Specialized job board for the Spanish wine industry. Companies can post job listings while job seekers 
                          browse positions in wineries, distributors, and related businesses. Features include category pages, 
                          location-based searching, and automated social media posting.
                        </p>
                        <a 
                          href="https://empleovino.es" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>

                  {/* Attention au Portefeuille */}
                  <article className="border-b border-gray-100 pb-16 last:border-b-0">
                    <div className="space-y-6">
                      <div className="aspect-video overflow-hidden rounded-lg">
                        <StaticImage
                          src="../images/attentionauportefeuille.jpg"
                          alt="Attention au Portefeuille Preview"
                          className="w-full h-full object-cover"
                          transformOptions={{
                            fit: "cover",
                            cropFocus: "center"
                          }}
                          placeholder="blurred"
                        />
                      </div>
                      <div>
                        <a href="https://www.attentionauportefeuille.com" className="inline-block" target="_blank" rel="noopener noreferrer">
                          <h3 className="text-4xl text-brand-600 leading-tight tracking-tight mb-4">Attention au Portefeuille</h3>
                        </a>
                        <p className="text-gray-700 leading-relaxed">
                          A French affiliate website focused on curated gift guides, helping users discover perfect presents 
                          while maintaining budget consciousness.
                        </p>
                        <a 
                          href="https://www.attentionauportefeuille.com" 
                          className="inline-block text-brand-600 hover:text-brand-700 hover:underline mt-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Site →
                        </a>
                      </div>
                    </div>
                  </article>
                </div>
              </main>

              {/* Sidebar */}
              <aside className="lg:col-span-3 lg:col-start-10">
                <div className="sticky top-8 space-y-8">
                  {/* Project Links */}
                  <div className="border-l-4 border-brand-200 pl-4">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">Projects</h2>
                    <div className="space-y-3">
                      <a href="https://cinetimes.org" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">Cinetimes</a>
                      <a href="https://fotoia.es" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">Fotoia</a>
                      <a href="https://www.websitehunt.co" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">WebsiteHunt</a>
                      <a href="https://proximovie.com" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">Proximovie</a>
                      <a href="https://alternativas.io" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">Alternativas</a>
                      <a href="https://empleovino.es" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">Empleovino</a>
                      <a href="https://www.attentionauportefeuille.com" className="block text-gray-700 hover:text-brand-600" target="_blank" rel="noopener noreferrer">Attention au Portefeuille</a>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default ProjectsPage;